<template>
    <nav class="navbar ks-navbar">
    <div class="navbar-brand">
        <div class="ks-navbar-logo" title="Partner Management Program">
            <a href="" class="ks-logo">
                <img src="/assets/img/flat_logo.png" width="25" class="mx-4" style="filter: grayscale(1) invert(1);">
                <span class="hidden-lg">PMP</span>
            </a>
        </div>
    </div>

    <div class="ks-wrapper">
        <nav class="nav navbar-nav">

            <div class="ks-navbar-menu">
                <router-link class="nav-item nav-link" to="/hris" :class="{'ks-active': $route.name == 'hris'}"><span>HRISV3</span></router-link>
                <router-link class="nav-item nav-link" to="/crf" :class="{'ks-active': routeName == 'crf'}"><span>CRF</span></router-link>
                <router-link class="nav-item nav-link" to="/quotation" :class="{'ks-active': routeName == 'quotation'}" v-if="data.priveledge == 'S'"><span>Quotation</span></router-link>
                <router-link class="nav-item nav-link" to="/prs" :class="{'ks-active': $route.name == 'prsnmd'}"><span>PRS (NMD)</span></router-link>
                <router-link class="nav-item nav-link" to="/soa" :class="{'ks-active': $route.name == 'soa'}"><span>SOA</span></router-link>

                   <!--  <router-link class="nav-item nav-link" to="/partners" :class="{'ks-active': $route.name.includes('partners')}"><span>Partners</span></router-link>
                <router-link class="nav-item nav-link" disabled to="/activity" :class="{'ks-active': $route.name == 'activity'}"><span>Activity</span></router-link>
                <router-link class="nav-item nav-link" to="/reports" :class="{'ks-active':  $route.name.includes('reports')}"><span>Reports</span></router-link>
                <router-link class="nav-item nav-link" to="/campaign" :class="{'ks-active': $route.name == 'campaign'}"><span>Campaign</span></router-link>-->
                <router-link class="nav-item nav-link" to="/contacts" :class="{'ks-active': $route.name.includes('contacts')}"><span>Contacts</span></router-link> 
            </div>

            <div class="ks-navbar-actions">
                <!-- END NAVBAR NOTIFICATIONS -->
                <div class="nav-item dropdown ks-user">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:void(0);" role="button" aria-haspopup="true" aria-expanded="false">
                        <!-- <span class="ks-avatar">
                            <img src="//pmp.ardentnetworks.com.ph/modules/getImage" width="36" height="36">
                        </span> -->
                        <span class="ks-info">
                            <span class="ks-name">{{data.name}}</span>
                            <span class="ks-description"></span>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" >
                        <a class="dropdown-item" @click="logout">
                            <span class="la la-sign-out ks-icon" aria-hidden="true"></span>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>

            </div>

        </nav>

        <nav class="nav navbar-nav ks-navbar-actions-toggle">
            <a class="nav-item nav-link" href="javascript:void(0);">
                <span class="la la-ellipsis-h ks-icon ks-open"></span>
                <span class="la la-close ks-icon ks-close"></span>
            </a>
        </nav>

        <nav class="nav navbar-nav ks-navbar-menu-toggle">
            <a class="nav-item nav-link" href="javascript:void(0);">
                <span class="la la-bars ks-icon ks-open"></span>
                <span class="la la-close ks-icon ks-close"></span>
            </a>
        </nav>
    </div>
</nav>

<!-- <div class="ks-page-content"> -->
   <div class="ks-page-container">
        <div class="ks-column ks-page">
            <router-view v-slot="{ Component }" name="main">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
            <div class="ks-footer p-3 bg-white">
                <small class="text-muted ks-copyright d-flex flex-row align-items-center justify-content-between">
                    <span class="logo-mis align-items-center justify-content-center"></span> 
                    <small class="fw-500">Version 4.0</small>
                </small>
            </div>
        </div>
    </div>

    <jl-alert />
</template>

<script>
export default {
    data() {
        return {
            data: {}
        }
    },
    methods: {
        async me() {
            this.data = await this.API.ME();  
            // this.$store.commit.StoreDetail(this.data)

            this.$store.commit({
            type: 'StoreDetail',
                user_detail : await this.API.ME()
            })
            // console.log(this.$store.state.user_detail)
        },
    async logout(){
            await this.API.logout()
            window.location.reload()
        }
    },
    computed :{
        routeName(){
            let data = ''
            switch(this.$route.name){
                case 'active' : 
                case 'approval' :
                case 'posting':
                    data = 'crf'
                break
                case 'quotation approval': 
                    data = 'quotation'
                break
        
            }

            return data
        }
    },
    mounted() {
        this.me();

        let btn = document.querySelector('.ks-navbar-actions-toggle');
        btn.addEventListener('click', () => {
            let nav = document.querySelector('.ks-navbar-actions');
            if(!nav) return;

            if(!btn.classList.contains('ks-open')) {
                btn.classList.add('ks-open')
                nav.classList.add('ks-open');
            }
            else {
                btn.classList.remove('ks-open')
                nav.classList.remove('ks-open');
            }
            
        });

        let nbtn = document.querySelector('.ks-navbar-menu-toggle');
         nbtn.addEventListener('click', () => {
            let nav = document.querySelector('.ks-navbar-menu');
            if(!nav) return;

            if(!nbtn.classList.contains('ks-open')) {
                nbtn.classList.add('ks-open')
                nav.classList.add('ks-open');
            }
            else {
                nbtn.classList.remove('ks-open')
                nav.classList.remove('ks-open');
            }   
        });
    }
}
</script>